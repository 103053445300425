export const mainDrsForm = {
  cpu_to_ram_balance: 0.5,
  vm_to_host_balance: 0.5,
  genshare: 0,
  generate_group_random: 0,
  nhosts: 4,
  nvms: 10,
  host_ram_averege: 64,
  host_ram_min: 32,
  host_ram_max: 312,
  host_cpu_averege: 64,
  host_cpu_min: 32,
  host_cpu_max: 312,
  vm_ram_averege: 4,
  vm_ram_min: 2,
  vm_ram_max: 12,
  vm_cpu_averege: 4,
  vm_cpu_min: 2,
  vm_cpu_max: 12,
  do_square: 3,
  lowboundneed: 1,
  balance_individual_load: 5,
  highboundneed: 1,
  super_highboundneed: 1,
  allowed_move_items: 0,
  CPUs_influence: 1,
  RAMs_influence: 1,
  add_hint: 0.5,
  do_move_limit: 3,
  do_share_max_limit: 5,
  moving_square: 0,
  allow_underload: 0,
};
