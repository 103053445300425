import {
  I_DrsPlaygroundState,
  I_GeneratedTask,
} from "@/store/modules/drsPlayground/lib/models/interfaces";

export default {
  getGeneratedTasks: (state: I_DrsPlaygroundState): I_GeneratedTask[] => {
    return state.generatedTasks;
  },
  getSelectedViewItem: (
    state: I_DrsPlaygroundState
  ): I_GeneratedTask | null => {
    return state.selectedViewItem;
  },
  getSelectedViewItemGraphics: (state: I_DrsPlaygroundState): any[] => {
    return state.selectedViewItemGraphics;
  },
};
