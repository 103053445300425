import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import("../views/Products.vue"),
  },
  {
    path: "/product-detail-oc",
    name: "product-detail-oc",
    component: () => import("../views/ProductDetailOc.vue"),
  },
  {
    path: "/product-detail-procurator",
    name: "product-detail-procurator",
    component: () => import("../views/ProductDetailProcurator.vue"),
  },
  {
    path: "/product-detail-procurator-guest-tools",
    name: "product-detail-procurator-guest-tools",
    component: () => import("../views/ProductDetailProcuratorGuestTools.vue"),
  },
  {
    path: "/product-detail-remote-console",
    name: "product-detail-remote-console",
    component: () => import("../views/ProductDetailRemoteConsole.vue"),
  },
  {
    path: "/product-detail-sphere",
    name: "product-detail-sphere",
    component: () => import("../views/ProductDetailSphere.vue"),
  },
  {
    path: "/product-detail-storm-storage",
    name: "product-detail-storm-storage",
    component: () => import("../views/ProductDetailStormStorage.vue"),
  },
  {
    path: "/product-detail-teleport",
    name: "product-detail-teleport",
    component: () => import("../views/ProductDetailTeleport.vue"),
  },
  {
    path: "/drs-playground",
    name: "drs-playground",
    component: () => import("../views/DrsPlayground.vue"),
  },
  {
    path: "/view-drs-file",
    name: "view-drs-file",
    component: () => import("../views/ViewDrsFile.vue"),
  },
  {
    path: "/vacancy",
    name: "vacancy",
    component: () => import("../views/Vacancy.vue"),
  },
  {
    path: "/services-detail",
    name: "services-detail",
    component: () => import("../views/ServicesDetail.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
