export const getRandomFileName = (): string => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  const random = ("" + Math.random()).substring(2, 8);
  return timestamp + random;
};

const taskPattern = /task_id_.*=/;

export const getAllTaskCookies = (): string[] => {
  const cookies = document.cookie.split(";");
  const taskCookies = [];

  for (let cookie of cookies) {
    cookie = cookie.trim();

    if (cookie.match(taskPattern)) {
      taskCookies.push(cookie);
    }
  }
  return taskCookies;
};

export const removeAllTaskCookies = (): void => {
  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    cookie = cookie.trim();

    if (cookie.match(taskPattern)) {
      const currentName = cookie.split("=")[0];
      document.cookie = `${currentName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  }
  location.reload();
};

export const scanUserTask = (taskArray: string[]): string[] => {
  const result = [];
  for (let i = 0; i < taskArray.length; i++) {
    let matchResult = taskArray[i].match(taskPattern);
    let position = matchResult && matchResult[0] ? matchResult[0].length : 0;
    result.push(taskArray[i].substring(position));
  }
  return result;
};

export const createCookie = (name: string, value: string, minutes: number) => {
  let expires = "";
  if (minutes) {
    const date: any = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else expires = "";

  document.cookie = name + "=" + value + expires + "; path=/";
};

export const getCookie = (name: string): string => {
  let cookie: any = {};
  document.cookie.split(";").forEach((element) => {
    const split = element.split("=");
    cookie[split[0].trim()] = split.slice(1).join("=");
  });
  return cookie[name];
};
