import {
  I_DrsPlaygroundState,
  I_GeneratedTask,
} from "@/store/modules/drsPlayground/lib/models/interfaces";

export default {
  M_GET_DRS_ITEMS(
    state: I_DrsPlaygroundState,
    payload: I_GeneratedTask[]
  ): void {
    state.generatedTasks = payload;
  },
  M_SET_SELECTED_VIEW_ITEM(
    state: I_DrsPlaygroundState,
    payload: { selectedItem: I_GeneratedTask; graphics: any[] }
  ): void {
    state.selectedViewItem = payload.selectedItem;
    state.selectedViewItemGraphics = payload.graphics;
  },
};
