import { I_GenerateDrsForm } from "@/components/common/drs/modals/generateDrs/lib/models/interfaces";
import { I_GeneratedTask } from "@/store/modules/drsPlayground/lib/models/interfaces";
import { axiosRequest } from "@/composables/useAxios";
import {
  createCookie,
  getAllTaskCookies,
  getCookie,
  scanUserTask,
} from "@/store/modules/drsPlayground/lib/utils/storage";
import { constructTask } from "@/store/modules/drsPlayground/lib/utils/constructTask";
import { setCookiesAndGetItems } from "@/store/modules/drsPlayground/lib/utils/setCookiesAndGetItems";
import { constructGraphs } from "@/store/modules/drsPlayground/mappers/constructGraphs";
import { mainDrsForm } from "@/store/modules/drsPlayground/lib/config/mainForm";

export default {
  async A_GENERATE_DRS_MANUALLY(
    _context: any,
    payload: I_GenerateDrsForm
  ): Promise<string | void> {
    const webDrsIdCookie = getCookie("webdrsid");
    const sendFormData = mainDrsForm;

    sendFormData.host_ram_averege = payload.hostRamAverege;
    sendFormData.host_ram_min = payload.hostRamMin;
    sendFormData.host_ram_max = payload.hostRamMax;
    sendFormData.host_cpu_min = payload.hostCpuMin;
    sendFormData.host_cpu_max = payload.hostCpuMax;
    sendFormData.vm_ram_averege = payload.vmRamAverege;
    sendFormData.vm_ram_min = payload.vmRamMin;
    sendFormData.vm_ram_max = payload.vmRamMax;
    sendFormData.vm_cpu_min = payload.vmCpuMin;
    sendFormData.vm_cpu_max = payload.vmCpuMax;

    const sendData: any = {
      type: 1,
      drsfileinput: "RANDOMNAME",
      json_conf: sendFormData,
    };
    if (webDrsIdCookie) sendData.webdrsid = webDrsIdCookie;
    const { data } = await axiosRequest("tasks", "POST", sendData);

    await setCookiesAndGetItems(data);
  },
  async A_GENERATE_DRS_RANDOMLY(
    _context: any,
    payload: [number, number]
  ): Promise<string | void> {
    const webDrsIdCookie = getCookie("webdrsid");
    const sendFormData = mainDrsForm;

    sendFormData.cpu_to_ram_balance = payload[0];
    sendFormData.vm_to_host_balance = payload[1];

    const sendData: any = {
      type: 2,
      drsfileinput: "RANDOMNAME",
      json_conf: sendFormData,
    };
    if (webDrsIdCookie) sendData.webdrsid = webDrsIdCookie;
    const { data } = await axiosRequest("tasks", "POST", sendData);

    await setCookiesAndGetItems(data);
  },
  async A_GET_DRS_ITEM_BY_ID(
    _: any,
    payload: string
  ): Promise<I_GeneratedTask> {
    const webDrsIdCookie = getCookie("webdrsid") || "";
    const sendData = {
      webdrsid: webDrsIdCookie,
    };

    const data = await axiosRequest(
      `tasks/${payload}`,
      "POST",
      JSON.stringify(sendData)
    );

    if (data?.data?.enc_key) {
      createCookie("webdrsid", data.data.enc_key, 10);
    }

    return constructTask(data.data);
  },
  async A_GET_DRS_ITEMS(context: any, payload: string[] = []): Promise<void> {
    const result: I_GeneratedTask[] = [];
    const pendingKeys: string[] = [];

    const userTaskCandidates = getAllTaskCookies();
    const currentKeys = payload.length
      ? payload
      : scanUserTask(userTaskCandidates);
    for (const key of currentKeys) {
      const data = await context.dispatch("A_GET_DRS_ITEM_BY_ID", key);
      if (data.status.toLowerCase() === "pending") {
        pendingKeys.push(key);
      }
      data && result.push(data);
    }
    context.commit("M_GET_DRS_ITEMS", result);

    if (pendingKeys.length) {
      setTimeout(() => {
        context.dispatch("A_GET_DRS_ITEMS", pendingKeys);
      }, 2000);
    }
  },
  async A_SET_SELECTED_VIEW_ITEM(context: any, payload: string): Promise<void> {
    const findElement = context.getters.getGeneratedTasks.find(
      (item: I_GeneratedTask) => item.id === payload
    );
    if (!findElement) return;

    const jsonFileMatch = findElement.result1.match(/\//);
    const jsonName = jsonFileMatch?.input?.slice(
      (jsonFileMatch.index || 0) + 1
    );

    const { data } = await axiosRequest(`getdraw/${jsonName}`, "GET");
    let graphicsData: any[] = [];
    if (data) graphicsData = constructGraphs(data);

    context.commit("M_SET_SELECTED_VIEW_ITEM", {
      selectedItem: findElement,
      graphics: graphicsData,
    });
  },
  async A_INIT_PLACEMENT(context: any): Promise<void> {
    const lastGeneratedElement: I_GeneratedTask =
      context.getters.getGeneratedTasks
        .filter((item: I_GeneratedTask) => item.name.toLowerCase() === "gener")
        .at(-1);

    if (!lastGeneratedElement) return;

    const resultFileMatch = lastGeneratedElement.result1.match(/\//);
    const currentJsonName = resultFileMatch?.input?.slice(
      (resultFileMatch.index || 0) + 1
    );
    console.log(currentJsonName, "A_INIT_PLACEMENT");

    const sendData: any = {
      type: 2,
      drsfileinput: currentJsonName,
      json_conf: mainDrsForm,
    };

    const { data } = await axiosRequest("tasks", "POST", sendData);

    await setCookiesAndGetItems(data);

    console.log(data, "A_INIT_PLACEMENT", context.getters.getGeneratedTasks);

    // if (!findElement) return;

    // const jsonFileMatch = findElement.result1.match(/\//);
    // const jsonName = jsonFileMatch?.input?.slice(
    //   (jsonFileMatch.index || 0) + 1
    // );

    // const { data } = await axiosRequest(`getdraw/${jsonName}`, "GET");
    // let graphicsData: any[] = [];
    // if (data) graphicsData = constructGraphs(data);

    // context.commit("M_SET_SELECTED_VIEW_ITEM", {
    //   selectedItem: findElement,
    //   graphics: graphicsData,
    // });
  },
};
