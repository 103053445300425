import { API_I_Graphics } from "@/store/modules/drsPlayground/lib/models/interfaces";

const isValidJSON = (value: string): boolean => {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

export const constructGraphs = (data: API_I_Graphics): any[] => {
  const result: any[] = [];
  for (let i in data) {
    if (data[i] && isValidJSON(data[i])) {
      const parsedData = JSON.parse(data[i]);
      const title = parsedData?.layout?.title?.text || "";
      parsedData.layout.title.text = "";
      result.push({
        title,
        id: i,
        mainData: parsedData,
      });
    }
  }
  return result;
};
